import { Injectable, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import * as R from "ramda";

import { AbstractInjectBaseComponent } from "../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../core/decorators/ow-inject.decorator";
import { PlayerService } from "../../../../../player/providers/player.service";
import { DialogService } from "../../../../../shared/providers/dialog.service";
import { STOCK_VIEW } from "../../../shared-ui/mobile/consts/stock-view.const";
import { GetGameRankResultsResponse } from "../../api/core/interfaces/response/get-game-rank-results.response.interface";
import { ApiCoreGameRanksService } from "../../api/core/services/api-game-ranks.service";
import { RANK_CONFIG } from "../../base/custom/dialogs/ranking-game-details/config/rank.config";
import { RankingGameDetailsInfo } from "../../base/custom/dialogs/ranking-game-details-info/ranking-game-details-info.component";
import { RankGame } from "../../interfaces/custom/rank-game.interface";

@Injectable()
export abstract class AbstractRankDetailsGameComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractRankDetailsGameComponent>;
  @OwInject(ApiCoreGameRanksService) apiCoreGameRanksService: ApiCoreGameRanksService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(MAT_DIALOG_DATA) data: {
    rankId: number;
  };
  limit = 500;
  STOCK_VIEW = STOCK_VIEW;
  rank: RankGame;
  rankResults: GetGameRankResultsResponse;
  RANK_CONFIG = RANK_CONFIG.DEFAULT;

  FILTERS = [];
  activeFilter;

  ngOnInit() {
    this.getRank();
  }

  getRank() {
    this.getGameRank();
  }

  getGameRank() {
    this.apiCoreGameRanksService
      .getGameRank({
        rank_edition_id: this.data.rankId,
      })
      .subscribe(resp => {
        this.rank = resp;
        this.setRankConfig();
        this.getResult();
      });
  }

  setRankConfig() {
    this.RANK_CONFIG = R.clone(this.rank.rank_type === 2 ? RANK_CONFIG.BRANCH : RANK_CONFIG.DEFAULT);

    if (this.rank.parameters) {
      const columnsConfigName = this.rank.parameters.columns_config_name;

      if (columnsConfigName && RANK_CONFIG[columnsConfigName]) {
        this.RANK_CONFIG = R.clone(RANK_CONFIG[columnsConfigName]);
      }
    }
    if (this.rank?.parameters?.show_branch_sales_region === 1) {
      this.RANK_CONFIG.columns.forEach(column => {
        if (column.value === "branch_name") {
          column.header = "Region sprzedaży";
        }
      });
    }
    if (this.rank?.parameters?.show_branch_sales_region === 0) {
      this.RANK_CONFIG.columns.forEach(column => {
        if (column.value === "branch_name") {
          column.header = "Hala";
        }
      });
    }
  }

  getResult() {
    switch (this.rank.rank_type) {
      case 1:
        this.getGameRanksResults();
        break;

      case 2:
        this.getGameRankBranchesResult();
        break;
    }
  }

  getGameRanksResults() {
    const requestData = {
      rank_edition_id: this.rank.rank_edition_id,
      limit: this.getLimit(),
    };

    this.apiCoreGameRanksService.getGameRanksResults(requestData).subscribe(resp => {
      this.rankResults = resp;
      this.afterGetGameRankRequest();
    });
  }

  getGameRankBranchesResult() {
    const requestData = {
      rank_edition_id: this.rank.rank_edition_id,
      limit: this.getLimit(),
    };

    this.apiCoreGameRanksService.gameRankBranchesResult(requestData).subscribe(resp => {
      this.rankResults = resp;
      this.afterGetGameRankRequest();
    });
  }

  openRankDetailsInfo(rankEditionId) {
    this.dialogService.open(RankingGameDetailsInfo, {
      data: {
        rank: this.rank,
        rankEditionId: rankEditionId,
      },
    });
  }

  afterGetGameRankRequest() {
    this.replacePlaceholders();
  }

  replacePlaceholders() {
    const regex = new RegExp(`%([a-zA-Z0-9_]+)`, "g");

    Object.keys(this.rank.parameters || {}).forEach(key => {
      this.rankResults.results.forEach(item => {
        let value = this.rank.parameters[key];

        if (typeof value === "string") {
          let result = this.getRegexValue(regex, value);

          while (result) {
            value = value.replace(`%${result}`, item[result]);
            result = this.getRegexValue(regex, value);
          }

          item[key] = value;
        }
      });
    });
  }

  getRegexValue(regex: RegExp, string: string) {
    const results = regex.exec(string);
    return results && results[1];
  }

  getLimit() {
    return (this.rank.parameters && this.rank.parameters.limit) || this.limit;
  }

  close() {
    this.matDialogRef.close();
  }
}

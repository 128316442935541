import { Component } from "@angular/core";

import { AbstractRankDetailsGameComponent } from "../../../../abstract/custom/abstract-rank-game-details.component";
import * as moment from "moment";
import { OwDate } from "../../../../../../../../core/utility/ow-date.class";

@Component({
  selector: "app-ranking-details-game",
  templateUrl: "./ranking-details-game.component.html",
})
export class RankingDetailsGame extends AbstractRankDetailsGameComponent {
  rankStarted = null;
  rankFinished = null;
  owDate: OwDate = null;

  getGameRank() {
    this.apiCoreGameRanksService
      .getGameRank({
        rank_edition_id: this.data.rankId,
      })
      .subscribe(resp => {
        this.rank = resp;
        this.setRankConfig();
        this.getResult();

        if (this.rank.parameters?.date_start && this.rank.parameters?.date_end) {
          this.owDate = new OwDate();
          this.rankStarted = moment(this.rank.parameters.date_start, "YYYY-MM-DD").isSameOrBefore(this.owDate.momentDate);
          this.rankFinished = moment(this.rank.parameters.date_end, "YYYY-MM-DD").isSameOrBefore(this.owDate.momentDate);
        }
      });
  }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { SharedModule } from "../../../../../shared/shared.module";
import { SharedUiMobileModule } from "../../../shared-ui/mobile/shared-ui-mobile.module";
import { RankingDetailsGame } from "../../base/custom/dialogs/ranking-game-details/ranking-details-game.component";
import { RankingGameTableComponent } from "../../base/custom/dialogs/ranking-game-details/ranking-game-table/ranking-game-table.component";
import { RankingGameDetailsInfo } from "../../base/custom/dialogs/ranking-game-details-info/ranking-game-details-info.component";
import { RankingListComponent } from "../../base/custom/dialogs/ranking-list/ranking-list.component";
import { OwPerfectScrollbarModule } from "@oskarwegner/ow-perfect-scrollbar";

@NgModule({
  declarations: [RankingListComponent, RankingDetailsGame, RankingGameTableComponent, RankingGameDetailsInfo],
  imports: [CommonModule, SharedModule, SharedUiMobileModule, OwPerfectScrollbarModule],
  exports: [RankingGameTableComponent],
})
export class RankingsModule {}
